import { CommonGameCell, IGameCellProps } from '@/features/recreation/component/game-cell'
import classNames from 'classnames'
import styles from './index.module.css'

export default function GameCellOk(props: IGameCellProps) {
  return (
    <CommonGameCell
      {...props}
      className={classNames(props?.className, styles['game-cell-ok-root'])}
      noShowOnlineUsers
    />
  )
}
