import classNames from 'classnames'
import LazyImage, { Type } from '@/components/lazy-image'
import { oss_ra_image_domain_address } from '@/constants/oss'
import { useMemo } from 'react'
import { RaHallGameCardTypeProps, RaHallGameListProps } from '@/typings/api/game'
import { HashStrategyTypeEnum, getHashStrategyTypeBgClassName, getHashStrategyTypeName } from '@/constants/game'
import { getGameDisableStatus } from '@/helper/game'
import { Image } from '@nbit/arco'
import { useCommonStore } from '@/store/common'
import Icon from '@/components/icon'
import GameOnlineUsers from '@/features/recreation/component/game-online-users'
import DisableGame from '@/features/recreation/component/disable-game'
import styles from './index.module.css'

export interface IGameCellProps {
  /** 样式 */
  className?: string
  /** dom id */
  id?: string
  /** 游戏数据 */
  data: RaHallGameListProps & RaHallGameCardTypeProps
  /** 是否记载中 */
  loading?: boolean
  /** 是否展示 hover 样式 */
  isHover?: boolean
  /** 是否可见（处理轮播时样式） */
  isVisible?: boolean
  /** 是否展示游戏 hover 遮罩样式，默认不展示 */
  isShowHoverMask?: boolean
  /** 是否展示在线人数，默认 false 展示 */
  noShowOnlineUsers?: boolean
  /** 点击进入进入游戏 */
  onEnter?: (v) => void
  /** 轮播下一页 */
  onNext?: () => void
}

function CommonGameCell(props: IGameCellProps) {
  const {
    className,
    id,
    data,
    loading,
    isVisible = true,
    isHover = true,
    noShowOnlineUsers = false,
    isShowHoverMask = false,
    onEnter,
    onNext,
  } = props || {}
  const { localeInfo } = useCommonStore()

  /** 是否配置了游戏图片 */
  const isGameImage = useMemo(() => {
    return !!data?.pcThumbnail
  }, [data?.pcThumbnail])

  /** 生成 RA(哈希) 游戏策略数据列表 */
  const hashStrategyList = Object.keys(HashStrategyTypeEnum).map((key: string) => {
    const strategy = HashStrategyTypeEnum[key]
    return {
      id: strategy,
      text: getHashStrategyTypeName(strategy),
      bg: getHashStrategyTypeBgClassName(strategy),
    }
  })

  /** 渲染 RA(哈希) 游戏策略模块 */
  const onRenderRaGameStrategy = (item: RaHallGameListProps) => {
    const extra = item?.extraJson?.strategy || ''
    if (!extra) return

    const handleExtra = extra?.split(',')
    if (!handleExtra) return null

    return handleExtra?.map(params => {
      const items = hashStrategyList?.find(v => v?.id === params)
      return (
        <div key={items?.id} className={`strategy-item number-bg ${items?.bg}`}>
          {items?.text}
        </div>
      )
    })
  }

  /** 处理游戏标题 */
  const onGetGameName = (v, index = 0) => {
    const code = v?.extraJson?.projectCode
    const name = v?.name || v?.groupName
    const currentText = name?.split(',')?.[index]
    if (code) {
      return currentText || name || ''
    }
    return name
  }

  /** 处理 RA(哈希) 游戏标题栏标题 */
  const onGetRaHeaderTitle = v => {
    const code = v?.extraJson?.projectCode
    const name = v?.name || v?.groupName
    const currentText = name?.split(',')?.[1]
    return code ? currentText || name || '' : ''
  }

  return (
    <div className={classNames(styles['game-cell-root'], className)} id={id}>
      <div className="splide-game-box">
        {!isGameImage && (
          <div className="item item-bg item-preview">
            <LazyImage
              imageType={Type.png}
              hasTheme
              className="item-image"
              src={`${oss_ra_image_domain_address}moren`}
            />
          </div>
        )}

        {isGameImage && (
          <div
            className={classNames('item', {
              'item-bg': !isGameImage,
              'item-hover': isHover && isVisible,
            })}
          >
            <LazyImage
              placeholder={<div className="item-loader" />}
              className="item-image"
              onClick={() => {
                if (loading || getGameDisableStatus(data)?.isClick) return
                onEnter?.(data)
              }}
              src={data?.pcThumbnail || ''}
            />

            <div className="own-header-wrap">{onGetRaHeaderTitle(data)}</div>

            <div className="strategy-wrap">{onRenderRaGameStrategy(data)}</div>

            {!localeInfo?.hideGameName?.includes(data?.groupCode || '') && (
              <div
                className={`${classNames('footer-text', {
                  'footer-index': getGameDisableStatus(data)?.isClick,
                })} ${
                  localeInfo?.specialGameImage?.includes(data?.supplier?.supplierCode || '')
                    ? 'footer-bottom'
                    : 'footer-bottom-spacing'
                }`}
              >
                {onGetGameName(data)}
              </div>
            )}

            {/* 游戏 hover 遮罩效果 */}
            {isShowHoverMask && isVisible && (
              <div
                className={classNames('banner-mask', {
                  'banner-mask-transition': isHover,
                })}
                onClick={() => {
                  if (getGameDisableStatus(data)?.isClick || !data?.isCurrentRegion) return
                  onEnter?.(data)
                }}
              >
                <div className="game-name">
                  <span>{onGetGameName(data)}</span>
                </div>

                <Icon name="icon_startgame" className="play-icon" />

                <div className="game-logo-wrap">
                  {data?.supplier?.supplierLogo ? (
                    <Image preview={false} className="game-image" src={data?.supplier?.supplierLogo} />
                  ) : (
                    <Icon name="icon_games_ncyuan_sidebar" className="game-icon" />
                  )}
                </div>

                <div className="game-desc">{data?.types?.[0]?.name || ''}</div>
              </div>
            )}

            {!isVisible && (
              <div
                className="invisible-wrap"
                onClick={e => {
                  e.stopPropagation()

                  if (getGameDisableStatus(data).isClick) return
                  onNext && onNext()
                }}
              />
            )}

            {/* 禁用区域 和 维护中的游戏 */}
            {getGameDisableStatus(data)?.isClick && <DisableGame value={data} />}
          </div>
        )}

        {!noShowOnlineUsers && <GameOnlineUsers livePt={data?.livePt || 0} />}
      </div>
    </div>
  )
}
export { CommonGameCell }
