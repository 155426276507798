/**
 * 第三方游戏
 */
import LazyImage from '@/components/lazy-image'
import { Spin } from '@nbit/arco'
import { RaHallGameCardTypeProps } from '@/typings/api/game'
import classNames from 'classnames'
import { useCommonStore } from '@/store/common'
import { ThemeEnum } from '@/constants/base'
import styles from './index.module.css'

type ISupplierGameProps = {
  className?: string
  enterId?: number
  loading?: boolean
  /** 是否可以展示 hover 样式 */
  isHover?: boolean
  /** 是否可见（处理轮播时样式） */
  isVisible?: boolean
  /** 轮播下一页 */
  onNext?: () => void
  data: RaHallGameCardTypeProps
  onEnter?: (v) => void // 点击进入进入游戏
}

export default function SupplierGame(props: ISupplierGameProps) {
  const { theme } = useCommonStore()
  const { data, enterId, loading, className, isVisible = true, isHover = true, onEnter, onNext } = props

  return (
    <div className={classNames(styles['supplier-game-wrap'], className)}>
      <Spin loading={data?.id === enterId && loading}>
        <div
          className={classNames('item', {
            'item-bg': !data?.logo,
            'item-hover': isHover && isVisible,
          })}
          onClick={() => {
            onEnter?.(data)
          }}
        >
          {data?.logo && (
            <LazyImage
              className="item-image"
              src={theme === ThemeEnum.dark ? data?.logo : data?.whiteLogo || ''}
              placeholder={<div className="item-loader" />}
            />
          )}

          {!isVisible && (
            <div
              className="invisible-wrap"
              onClick={e => {
                e.stopPropagation()
                onNext && onNext()
              }}
            />
          )}
        </div>
      </Spin>
    </div>
  )
}
