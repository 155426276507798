/**
 * 禁用蒙层组建
 */
import Icon from '@/components/icon'
import { t } from '@lingui/macro'
import { getGameDisableStatus } from '@/helper/game'
import style from './index.module.css'

function DisableGame(props) {
  const { value } = props

  /**
   * 获取游戏维护提示文案及图标名称
   */
  const onGetGameStatusInfo = () => {
    switch (value) {
      case getGameDisableStatus(value)?.maintenanceStatus:
        return {
          text: t`features_entertainment_area_quota_conversion_index_ueleprpp22`,
          icon: 'icon_maintenance',
        }
      case getGameDisableStatus(value)?.isCurrentRegionStatus:
        return { text: t`features_home_component_disable_game_index_2vv8jhk4ek`, icon: 'icon_regionlocking' }
      default:
        return { text: '', icon: '' }
    }
  }

  return (
    <div className={style['own-maintain-box']}>
      <div>
        <Icon className="box-icon" name={onGetGameStatusInfo().icon} />
        <div className="own-text">{onGetGameStatusInfo()?.text}</div>
      </div>
    </div>
  )
}

export default DisableGame
