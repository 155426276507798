import { MinTotalForShowMore } from '@/constants/game'
import { InitThemeColor } from '@/constants/base'
import { systemThemeColor } from '../env'

/**
 * 根据数量判断是否隐藏”查看更多“
 */
export const shouldHiddenMoreByTotal = (total: number) => total <= MinTotalForShowMore

/**
 * 获取游戏大厅tab存储key
 */
export const getGameTabCacheKey = <T extends number>(gamesId: T): `home-tab-${T}` => `home-tab-${gamesId}`

/**
 * 获取游戏大厅菜单存储 key
 */
export const getGameMenuCacheKey = <T extends string>(typeCd: T): `home-menu-${T}` => `home-menu-${typeCd}`

/**
 * 根据窗口尺寸获取搜索列表媒体查询数据 (2z 主题)
 */
export const getSearchListMediaQuery2z = () => {
  const w = window.innerWidth
  switch (true) {
    case w >= 1515:
      return { rows: 8, gap: 10 }
    case w >= 1350 && w < 1515:
      return { rows: 7, gap: 10 }
    case w >= 1160 && w < 1350:
      return { rows: 6, gap: 10 }
    case w < 1160:
      return { rows: 5, gap: 10 }
    default:
      return { rows: 8, gap: 10 }
  }
}

/**
 * 根据窗口尺寸获取搜索列表媒体查询数据(ok主题)
 */
export const getSearchListMediaQueryOk = () => {
  const w = window.innerWidth
  switch (true) {
    case w >= 1515:
      return { rows: 6, gap: 16 }
    case w >= 1350 && w < 1515:
      return { rows: 6, gap: 16 }
    case w >= 1160 && w < 1350:
      return { rows: 5, gap: 16 }
    case w < 1160:
      return { rows: 5, gap: 16 }
    default:
      return { rows: 6, gap: 16 }
  }
}

/**
 * 根据窗口尺寸获取搜索列表媒体查询数据
 */
export const getSearchListMediaQuery = () => {
  switch (systemThemeColor) {
    case InitThemeColor['2z']:
      return getSearchListMediaQuery2z()
    case InitThemeColor.ok:
      return getSearchListMediaQueryOk()
    default:
      return getSearchListMediaQuery2z()
  }
}
