import { systemThemeColor } from '@/helper/env'
import { InitThemeColor } from '@/constants/base'
import { IGameCellProps } from '@/features/recreation/component/game-cell'
import GameCell2z from '@/features/recreation/theme/2z/game-cell'
import GameCellOk from '@/features/recreation/theme/ok/game-cell'

export default function GameCell(props: IGameCellProps) {
  switch (systemThemeColor) {
    case InitThemeColor['2z']:
      return <GameCell2z {...props} />
    case InitThemeColor.ok:
      return <GameCellOk {...props} />
    default:
      return <GameCell2z {...props} />
  }
}
